import {
  LayoutAppLogo,
  AppLogoResponsive,
} from "~/components/Layout/LayoutAppLogo";
import { NavigationAppBar } from "~/components/NavigationAppBar";
import { Icon } from "~/ui-library";
import { ArrowPathIcon } from "~/ui-library/icons";

export type AuthenticationLayoutProps = {
  /** renderable to display while on this page next to the spinner */
  loadingText: React.ReactNode;
  /** Any react element to render, this will typically be the next.js page */
  page: React.ReactElement;
};

export const AuthenticationLayout: React.FC<AuthenticationLayoutProps> = ({
  loadingText,
  page,
}) => (
  <div className="relative flex flex-col items-center justify-center">
    <NavigationAppBar className="absolute top-0 z-20 flex w-full items-center justify-center">
      <LayoutAppLogo logo={<AppLogoResponsive />} />
    </NavigationAppBar>
    <div className="z-10 flex min-h-screen -translate-y-1/4 flex-col items-center justify-center bg-white">
      <div className="mb-m min-h-fit min-w-[30px]">
        <Icon
          SvgIcon={ArrowPathIcon}
          className="animate-spin fill-transparent "
          size="scale"
        />
      </div>

      <div>{loadingText}</div>
    </div>
    {page}
  </div>
);
